import {ITenantConfig} from '../../../Models/Client/Config/ITenantConfig'
import {Asset} from 'expo-asset'

const ToprConfig: ITenantConfig = {
  AppName: 'Topr Wallet',
  SystemName: 'topr',
  MainColor: '#C0D001', //'#97af25',
  MainColorDark: '#97af25',
  //MainColor: '#97af25', //'#C1D101', //topr groen
  TermsPdf: {
    nl: Asset.fromModule(
      require('../../../../assets/file/topr/terms-and-conditions-nl.pdf'),
    ).uri,
    en: Asset.fromModule(
      require('../../../../assets/file/topr/terms-and-conditions-en.pdf'),
    ).uri,
  },
  TenantID: 9,
  Version: {Ios: '2.0.33', Android: '2.0.33'},
  BaseDeepLink: 'https://www.toprwallet.nl/',
}

export default ToprConfig
