import {IServiceConfig} from '../../Models/Client'
import {LogLevel} from '../../Models/Client/Enums'

const DebugConfig: IServiceConfig = {
  ToprAuthUrl: 'https://localhost:5001/', //ngrok niet nodig, web
  BaseUrl: 'https://89cd-90-145-223-18.ngrok-free.app/',
  //CmsUrl: 'http://localhost:51526',
  CmsUrl: 'https://0b43-90-145-223-18.ngrok.io',
  //CmsUrl: 'https://cms.toprwallet.nl',
  ClientId: '797e1a1a-70bf-4ca9-9320-5e30ae98704c', //toprclientid:6 toprConsumerapp
  Secret: '',
  ShowLogging: false,
  LogLevel: LogLevel.Info,
}

export default DebugConfig
